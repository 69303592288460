function TextToSpeechView(props) {
    return (
        <>
            <div className="bg-white font-['roboto'] border-x-[1px] border-[#489BF6] border-b-[1px] px-4 leading-[22px] font-[500]">
                <div className="flex items-center justify-between">
                    <div>
                        <h5 className="text-[#1C2347] text-[18px] pt-5">
                            AI Language model
                        </h5>
                        <p className="text-[14px] font-[400] text-[#595959] py-2">
                            Our cutting-edge multilingual speech model can
                            produce lifelike speech across 29 languages.
                        </p>
                    </div>
                    <div className="flex items-center  text-[#489BF6] cursor-pointer">
                        <i className="fa fa-cog pr-1" aria-hidden="true"></i>
                        <p className="text-[16px] font-[500]">Voice Settings</p>
                    </div>
                </div>
                <div>
                    <h5 className="text-[#1C2347] text-[18px] pt-5">
                        Select voice
                    </h5>
                    <div className="py-2 relative">
                        <button
                            className="flex justify-between text-[#595959] border-[1px] border-[#1C2347] hover:bg-[#489BF636] w-full focus:ring-[1px] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center"
                            type="button"
                            onClick={props.toggleShowDropdown}
                        >
                            --- Select voice ---{" "}
                            <i className="fa fa-angle-down"></i>
                        </button>

                        {props.showDropdown && (
                            <div
                                id="dropdown"
                                className="bg-white absolute rounded-lg shadow-xl w-full"
                            >
                                <ul className="py-2 text-sm text-gray-700 ">
                                    <li
                                        className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                        onClick={props.toggleShowDropdown}
                                    >
                                        Voice 1
                                    </li>
                                    <li
                                        className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                        onClick={props.toggleShowDropdown}
                                    >
                                        Voice 2
                                    </li>
                                    <li
                                        className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                        onClick={props.toggleShowDropdown}
                                    >
                                        Voice 3
                                    </li>
                                    <li
                                        className="block px-4 py-2 hover:bg-[#489BF636] hover:cursor-pointer"
                                        onClick={props.toggleShowDropdown}
                                    >
                                        Voice 3
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <h5 className="text-[#1C2347] text-[18px] pt-5">Text</h5>
                    <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-[14px] text-[#959595] bg-white rounded-lg border-[1px] border-[#C1C3C0] outline-0 focus:border-[#489BF6] my-2"
                        placeholder="Input your text in this field, the model performs optimally with longer segments."
                    ></textarea>
                </div>
                <div className="flex justify-end">
                    <div className="text-[#959595] font-[400]">
                        <p>Words write : 10/5000</p>
                        <p>Total word remaining : 76461</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextToSpeechView;
