import { OTPService, FirebaseAuthService, UserService } from "../../services";
import SignInView from "./SignIn.view";
import { useState } from "react";
import CountryCodes from "../../assets/json/CountryCodes.json";

function SignInContainer(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [country, setCountry] = useState("IN");
    const [authType, setAuthType] = useState("Email and Password");
    const [searchTerm, setSearchTerm] = useState("");
    const [enterPhoneOtp, setEnterPhoneOtp] = useState(false);
    const [enterEmailOtp, setEnterEmailOtp] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneOTP, setPhoneOTP] = useState("");
    const [emailOTP, setEmailOTP] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);

    const messageController = () => setShowMessage(!showMessage);

    const togglePhone = (i) => {
        if (i) setPhoneCode(i);
    };

    const toggleCountry = (i) => {
        if (i) setCountry(i);
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSearchInput = (e) => setSearchTerm(e.currentTarget.value);

    const toggleAuthType = (i) => {
        if (i) setAuthType(i);
    };

    const toggleEnterEmailOtp = async (i) => {
        if (i) {
            if (!email.includes("@") || !email.includes(".")) {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText("Enter a valid Email!");
            } else {
                const data = await OTPService.requestOTP("email", email);
                setShowMessage(true);
                setMessageClass(data.success ? "Success" : "error");
                setMessageText(data.message);
                setEnterEmailOtp(i);
            }
        }
    };

    const handleInputChange = (e) => {
        if (e.target.name === "phoneNumber") setPhoneNumber(e.target.value);
        if (e.target.name === "phone-otp") setPhoneOTP(e.target.value);
        if (e.target.name === "email-otp") setEmailOTP(e.target.value);
        if (e.target.name === "email") setEmail(e.target.value);
        if (e.target.name === "password") setPassword(e.target.value);
    };

    const toggleEnterPhoneOtp = async (i) => {
        let data;
        if (phoneNumber.length !== 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid phone number!");
        } else {
            data = await OTPService.requestOTP("phone", phoneNumber);
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            setEnterPhoneOtp(i);
        }
    };

    const loginWithGoogle = async () => {
        try {
            const data = await FirebaseAuthService.signinWithGoogle();
            return data.user.accessToken;
        } catch (error) {
            console.error(error);
        }
    };

    const login = async (type) => {
        let loginParams = {};
        switch (type) {
            case "email":
                if (!email.includes("@") || !email.includes(".")) {
                    setShowMessage(true);
                    setMessageClass("error");
                    setMessageText("Enter a valid Email!");
                }
                loginParams = { email, password };
                break;
            case "phone":
                loginParams = { phoneNumber, otp: phoneOTP };
                break;
            case "google":
                loginParams = { token: await loginWithGoogle() };
                break;
            case "email-otp":
                loginParams = { email, otp: emailOTP };
                break;
            default:
                break;
        }
        const data = await UserService.login(loginParams, type);
        setShowMessage(true);
        setMessageClass(data.success ? "Success" : "error");
        setMessageText(data.message);
    };

    return (
        <SignInView
            searchTerm={searchTerm}
            togglePhone={togglePhone}
            toggleCountry={toggleCountry}
            toggleDropdown={toggleDropdown}
            phoneCode={phoneCode}
            country={country}
            isOpen={isOpen}
            authType={authType}
            toggleAuthType={toggleAuthType}
            CountryCodes={CountryCodes}
            handleSearchInput={handleSearchInput}
            toggleEnterEmailOtp={toggleEnterEmailOtp}
            enterEmailOtp={enterEmailOtp}
            enterPhoneOtp={enterPhoneOtp}
            handleInputChange={handleInputChange}
            toggleEnterPhoneOtp={toggleEnterPhoneOtp}
            login={login}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
        />
    );
}

export default SignInContainer;
