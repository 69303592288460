function SidebarView(props) {
    return (
        <>
            <div className="font-['roboto']">
                <div className="mx-5 h-full overflow-auto">
                    <div className="flex items-center h-[120px] max-md:h-16">
                        <img src="../images/whitelogo.webp" alt="" />
                    </div>
                    <div className="h-[calc(100%-240px)] text-white">
                        <div className="">
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/dashboard.webp"
                                    alt=""
                                    className="pr-2"
                                />

                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    Dashboard
                                </p>
                            </div>

                            <p className="text-[13px] text-[#969696] text-left">
                                Api
                            </p>

                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/apidocumentation.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white ">
                                    APIs documentation
                                </p>
                            </div>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/apikeys.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    APIs Keys
                                </p>
                            </div>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/apipricing.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    APIs pricing
                                </p>
                            </div>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/apiusage.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    APIs Usage
                                </p>
                            </div>
                            <p className="text-[13px] text-[#969696] text-left">
                                Product
                            </p>

                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/aivoice.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    AI Voice Cloning
                                </p>
                            </div>
                            <p className="text-[13px] text-[#969696] text-left">
                                Support
                            </p>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/myaccount.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    My account
                                </p>
                            </div>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/faq.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    FAQs
                                </p>
                            </div>
                            <div className="flex my-4 hover:bg-[#489bf6] rounded-lg pl-4 p-3 group cursor-pointer">
                                <img
                                    src="../images/dashboard-sidebar/logout.webp"
                                    alt=""
                                    className="pr-2"
                                />{" "}
                                <p className="text-[#CACACA] text-[16px] text-left leading-[18.75px] group-hover:text-white">
                                    Logout
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gradient-to-b from-blue-500 to-blue-900 text-center font-['roboto'] rounded-md mb-2 flex flex-col items-center justify-end mt-20 h-[100px]">
                        <div className="rounded-full bg-white w-[80px] h-[80px] flex flex-col items-center justify-center border-[8px] -mt-72 border-[#1C2347]">
                            <img
                                src="../images/dashboard-sidebar/bookademo.webp"
                                alt=""
                            />
                        </div>
                        <p className="text-sm font-semibold p-5 underline">
                            Book a Demo Call{" "}
                            <i
                                className="fa fa-external-link-square pl-2"
                                aria-hidden="true"
                            ></i>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SidebarView;
