function ChargesView(props) {
    return (
        <>
            {data.map((item, index) => (
                <div key={index} className="font-['roboto'] my-3">
                    <div className="border-[1px] border-[#D6E8FD] rounded-lg grid grid-cols-[7fr_3fr] items-center px-5 py-3">
                        <div className="pb-5 pt-2">
                            <h5 className="text-[#1C2347] text-[20px] font-[500] leading-[23px]">
                                {item.heading}
                            </h5>
                            <p className="text-[#595959] font-[400] text-[14px] pt-1">
                                {item.paragraph}
                            </p>
                            <div className="flex justify-between mt-5">
                                <div className="flex items-end">
                                    <h5 className="text-[#1C2347] font-[600] text-[32px] leading-[37.5px]">
                                        {item.price}/-
                                    </h5>
                                    <p className="text-[#595959] text-[14px] font-[400] py-1 px-2">
                                        Rupees Only
                                    </p>
                                </div>
                                <div className="flex items-end">
                                    <h5 className="text-[#1C2347] font-[600] text-[32px] leading-[37.5px]">
                                        {item.words}
                                    </h5>
                                    <p className="text-[#595959] text-[14px] font-[400] py-1 px-2">
                                        Words
                                    </p>
                                </div>
                                <div className="flex items-end">
                                    <h5 className="text-[#1C2347] font-[600] text-[14px] leading-[16x] pr-5">
                                        Validity period
                                    </h5>
                                    <p className="text-[#595959] text-[14px] font-[400] ">
                                        {item.validityPeriod}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end px-6">
                            <button className="bg-gradient-to-r hover:bg-gradient-to-l  from-blue-500 to-purple-500 px-10 py-3 hover:scale-[1.1] transition duration-300 rounded-lg text-white font-[600]">
                                Recharge
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default ChargesView;

const data = [
    {
        heading: "Basic charge",
        paragraph:
            "Unlock the power of words with our Basic Plan. Get 1,000 words for just ₹200, perfect for small projects and personal use.",
        price: "199",
        words: 1000,
        validityPeriod: "31-mar-2024 - 31-Apr-2024",
    },
    {
        heading: "Advance charge",
        paragraph:
            "Elevate your content with our Advance Plan. Enjoy 2,000 words for ₹300, designed for more comprehensive needs and deeper engagement.",
        price: "299",
        words: 3000,
        validityPeriod: "01-apr-2024 - 30-Apr-2024",
    },
    {
        heading: "Premium charge",
        paragraph:
            "Experience unmatched excellence with our Premium Plan. Access 5,000 words for ₹500, catering to extensive projects that demand the highest quality.",
        price: "499",
        words: 5000,
        validityPeriod: "01-apr-2024 - 30-Apr-2024",
    },
];
