function ClonedVoiceView(props) {
    return (
        <>
            <div className="grid grid-cols-4 max-[1800px]:grid-cols-3 max-[1450px]:grid-cols-2 max-[1450px]:justify-items-center max-[1100px]:grid-cols-1">
                {[...Array(8)].map((_, index) => (
                    <div className="font-['roboto'] my-2">
                        <div className="border-[1px] border-[#D6E8FD] rounded-md p-2 w-[323px]">
                            <div className="flex justify-between my-2">
                                <img
                                    src="../images/clonedvoice/clonedvoice-header.webp"
                                    alt=""
                                />
                                <p className="text-[12px] font-[400] text-[#3D3C46]">
                                    3 days Ago
                                </p>
                            </div>
                            <div className="my-5">
                                <h5 className="text-[#1C2347] text-[14px] font-[600]">
                                    Svayam Bhagavan Keshava Maharaj..
                                </h5>
                                <p className="text-[12px] font-[400] py-2 text-[#646464]">
                                    Hi I am the AI voice of Svayam Bhagavan
                                    Keshava Maharaj. In the journey to
                                    comprehend the concept of God, an intriguing
                                    approach is to explore it through
                                    <span className="text-[#489BF6] text-[14px] font-[400] cursor-pointer pl-28">
                                        .....more
                                    </span>
                                </p>
                            </div>
                            <div className="flex justify-between my-2">
                                <button className="rounded-l-[31px] rounded-r-[31px] bg-[#489BF626] flex items-center text-[16px] font-[400] text-[#489BF6] w-[100px] p-1">
                                    <i className="fa fa-play-circle pr-2 text-[32px]"></i>
                                    PLAY
                                </button>
                                <div className="p-2 flex">
                                    <img
                                        src="../images/clonedvoice/arrow-down.webp"
                                        className="pr-2"
                                        alt=""
                                    />
                                    <img
                                        src="../images/clonedvoice/trash.webp"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ClonedVoiceView;
