function SpeechToSpeechView(props) {
    return (
        <>
            <div className="text-black">
                This is the Speech to Speech Component
            </div>
        </>
    );
}

export default SpeechToSpeechView;
