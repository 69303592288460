import { useState } from "react";
import TextToSpeechView from "./TextToSpeech.view";
function TextToSpeechContainer(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleShowDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    return (
        <TextToSpeechView
            showDropdown={showDropdown}
            toggleShowDropdown={toggleShowDropdown}
        />
    );
}

export default TextToSpeechContainer;
