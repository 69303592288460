export default function NotFoundView() {
    return (
        <>
            <div className="bg-[F5F5F5] h-[100vh] grid place-items-center content-center">
                <img src="../images/oops.svg" alt="invincibleocean" />
                <h4 className="text-[30px] mt-[40px] mb-[10px] font-['roboto']">
                    PAGE NOT FOUND !
                </h4>
                <p className="text-[20px] text-[#777777] mb-[40px]">
                    we can’t seem to find the page you’re looking for !
                </p>
                <a href="/login">
                    <button className="bg-blue-100 text-white px-10 py-3 font-[600] hover:scale-[1.05] transition duration-300 ease-in-out hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600">
                        Go to Login Page
                    </button>
                </a>
            </div>
        </>
    );
}
