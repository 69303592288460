import axios from "axios";

const OTPService = {
    requestOTP: async (type, destination) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/otp/${type}/request`,
                {
                    destination,
                }
            );
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error.response.data));
            return { success: false, ...error.response.data };
        }
    },
    verifyOTP: async (type, destination, otp) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user/otp/${type}/verify`,
                {
                    destination,
                    otp,
                }
            );
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error.response.data));
            return { success: false, ...error.response.data };
        }
    },
};

export default OTPService;
