import SpeechToSpeech from "../SpeechToSpeech";
import TextToSpeech from "../TextToSpeech";

function SpeechView(props) {
    return (
        <>
            <div>
                <div className="grid grid-cols-2 justify-items-center pt-5">
                    <div
                        className={`${props.speechType === "Text-to-Speech" ? "speech-header-active" : "speech-header-inactive"}`}
                        onClick={props.changeSpeechType}
                    >
                        <div className=""></div>
                        <p className="">Text to speech</p>
                    </div>
                    <div
                        className={`${props.speechType === "Speech-to-Speech" ? "speech-header-active" : "speech-header-inactive"}`}
                        onClick={props.changeSpeechType}
                    >
                        <div className=""></div>
                        <p className="">Speech to speech</p>
                    </div>
                </div>
            </div>
            <div className="">
                {props.speechType === "Text-to-Speech" && <TextToSpeech />}
                {props.speechType === "Speech-to-Speech" && <SpeechToSpeech />}
            </div>
        </>
    );
}

export default SpeechView;
