import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";

import { AuthPage, DashboardPage, AIVoiceCloningPage } from "./pages";
import { Loader, NotFound } from "./components";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Page page={<DashboardPage />} />} />
                <Route path="/login" element={<Page page={<AuthPage />} />} />
                <Route
                    path="/ai-voice-cloning"
                    element={<Page page={<AIVoiceCloningPage />} />}
                />
                <Route path="*" element={<Page page={<NotFound />} />} />
            </Routes>
        </div>
    );
}

function Page(props) {
    return <Suspense fallback={<Loader />}>{props.page}</Suspense>;
}

export default App;
