import axios from "axios";

const UserService = {
    login: async (loginParams, type) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/user`,
                { ...loginParams, type: type }
            );
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error.response.data));
            return { success: false, ...error.response.data };
        }
    },
    signup: async (userData) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/user`,
                {
                    name: userData.name,
                    email: userData.email,
                    password: userData.password,
                    phoneNumber: userData.phoneNumber,
                }
            );
            return response.data;
        } catch (error) {
            console.error(JSON.stringify(error.response.data));
            return { success: false, ...error.response.data };
        }
    },
};

export default UserService;
