import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics, isSupported } from "firebase/analytics";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithPhoneNumber,
    RecaptchaVerifier,
} from "firebase/auth";

import { firebaseConfig } from "../../store/Firebase.config";

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

(async () => {
    // const analytics =
    if (await isSupported()) getAnalytics(app);
    // logEvent(analytics, 'notification_received');
})();

getPerformance(app);

export {
    signInWithPopup as signinWithPopup,
    signInWithPhoneNumber as signinWithPhoneNumber,
    RecaptchaVerifier,
    auth,
    googleProvider,
};
