import MessageView from "./Message.view";

export default function MessageContainer(props) {
    return (
        <>
            <MessageView
                messageController={props.messageController}
                messageText={props.messageText}
                messageClass={props.messageClass}
            />
        </>
    );
}
