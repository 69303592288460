import AddVoiceView from "./AddVoice.view";
function AddVoiceContainer(props) {
    function copyTextToClipboard() {
        const textToCopy = document.getElementById("textToCopy");
        const copyMessage = document.getElementById("copyMessage");
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy.innerText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        copyMessage.classList.remove("hidden");
        setTimeout(function () {
            copyMessage.classList.add("hidden");
        }, 1500);
    }

    return <AddVoiceView copyTextToClipboard={copyTextToClipboard} />;
}

export default AddVoiceContainer;
