import SignUpView from "./SignUp.view";
import { useState } from "react";
import CountryCodes from "../../assets/json/CountryCodes.json";
import { FirebaseAuthService, OTPService, UserService } from "../../services";

function SignUpContainer(props) {
    const [currentStep, setCurrentStep] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [country, setCountry] = useState("IN");
    const [searchTerm, setSearchTerm] = useState("");
    const [enterPhoneOtpSignup, setEnterPhoneOtpSignup] = useState(false);
    const [signUpEmailOtp, setSignUpEmailOtp] = useState(false);
    const [userData, setUserData] = useState({});

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSearchInput = (e) => setSearchTerm(e.currentTarget.value);

    const togglePhone = (i) => {
        if (i) setPhoneCode(i);
    };

    const toggleCountry = (i) => {
        if (i) setCountry(i);
    };

    const toggleCurrentStep = async (i) => {
        if (i) setCurrentStep(i);
        if (i === 2) {
            const data = await OTPService.verifyOTP(
                "email",
                userData.email,
                userData["email-otp"]
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
        }
        if (i === 3) {
            const data = await OTPService.verifyOTP(
                "phone",
                userData.phoneNumber,
                userData["phone-otp"]
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
        }
    };

    const toggleSignUpEmailOtp = async (i) => {
        if (!userData.email.includes("@") || !userData.email.includes(".")) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid Email!");
        } else {
            const data = await OTPService.requestOTP("email", userData.email);
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (i) setSignUpEmailOtp(i);
        }
    };

    const toggleEnterPhoneOtpSignup = async (i) => {
        if (userData.phoneNumber.length !== 10) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Enter a valid phone number!");
        } else {
            const data = await OTPService.requestOTP(
                "phone",
                userData.phoneNumber
            );
            setShowMessage(true);
            setMessageClass(data.success ? "Success" : "error");
            setMessageText(data.message);
            if (i) setEnterPhoneOtpSignup(i);
        }
    };

    const updateUserData = (e) =>
        setUserData({ ...userData, [e.target.name]: e.target.value });

    const signUpUser = async () => {
        const data = await UserService.signup(userData);
        setShowMessage(true);
        setMessageClass(data.success ? "Success" : "error");
        setMessageText(data.message);
    };

    const signUpWithGoogle = async () => {
        const data = await FirebaseAuthService.signinWithGoogle();
        setUserData({
            email: data.user.email,
            name: data.user.displayName,
        });
        setCurrentStep(2);
        setShowMessage(true);
        setMessageClass(data.success ? "Success" : "error");
        setMessageText(data.message);
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);

    return (
        <SignUpView
            currentStep={currentStep}
            CountryCodes={CountryCodes}
            handleSearchInput={handleSearchInput}
            searchTerm={searchTerm}
            togglePhone={togglePhone}
            toggleCountry={toggleCountry}
            toggleDropdown={toggleDropdown}
            phoneCode={phoneCode}
            country={country}
            isOpen={isOpen}
            enterPhoneOtpSignup={enterPhoneOtpSignup}
            signUpEmailOtp={signUpEmailOtp}
            toggleSignUpEmailOtp={toggleSignUpEmailOtp}
            toggleEnterPhoneOtpSignup={toggleEnterPhoneOtpSignup}
            toggleCurrentStep={toggleCurrentStep}
            updateUserData={updateUserData}
            signUpUser={signUpUser}
            showMessage={showMessage}
            messageText={messageText}
            messageClass={messageClass}
            messageController={messageController}
            signUpWithGoogle={signUpWithGoogle}
        />
    );
}

export default SignUpContainer;
