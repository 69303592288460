function AddVoiceView(props) {
    document.addEventListener("DOMContentLoaded", function () {
        // Select the elements
        const textToCopy = document.getElementById("textToCopy");
        const copyIcon = document.getElementById("copyIcon");
        const copyMessage = document.getElementById("copyMessage");

        // Add click event listener to the icon
        copyIcon.addEventListener("click", function () {
            // Create a temporary textarea element
            const textarea = document.createElement("textarea");
            textarea.value = textToCopy.innerText; // Set the value to the text inside <p>
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy"); // Copy the selected text
            document.body.removeChild(textarea); // Remove the textarea

            // Show copy message
            copyMessage.classList.remove("hidden");
            setTimeout(function () {
                copyMessage.classList.add("hidden");
            }, 1500); // Hide message after 1.5 seconds
        });
    });
    return (
        <>
            <div className="font-['roboto']">
                <div className="border-[1px] border-[#000000] border-dashed py-4 rounded-md grid justify-items-center group hover:bg-[#489BF636] duration-300 cursor-pointer hover:border-white hover:shadow-xl">
                    <img
                        src="../images/addvoice/addvoice.webp"
                        alt=""
                        className="py-2"
                    />
                    <h5 className="font-[600] text-[22px] leading-[26px] py-2">
                        Add instant voice
                    </h5>
                    <p className="text-[#646464] leading-[22px] text-[14px] font-[400] py-2 px-8 text-center">
                        Experience the future of digital interaction with our AI
                        voice cloning technology—where every word brings your
                        ideas to life in over 29 languages. Transform your
                        communications with lifelike speech that captivates and
                        engages audiences worldwide.
                    </p>
                </div>
                <h5 className="text-[20px] leading-[24px] text-[#1C2347] py-3 mt-4 font-[500]">
                    Recentely added
                    <span className="text-[#959595] pl-5">6/30</span>
                </h5>
                <div className="border-[1px] border-[#C5C6C5] bg-[#F9F9F9] rounded-md shadow-inset-lg overflow-y-scroll h-[300px] p-2">
                    <div className="grid grid-cols-3 gap-3 max-[1660px]:grid-cols-2  max-[1660px]:justify-items-center max-[1260px]:grid-cols-1 my-2">
                        {[...Array(5)].map((_, index) => (
                            <div className="w-[400px] max-md:w-[250px] bg-white rounded-md border-[1px] border-[#D6E8FD] p-2">
                                <div className="flex items-center pt-2">
                                    <div>
                                        <i className="fa fa-user-circle-o text-[#1C2347] text-[24px]"></i>
                                    </div>
                                    <div className="pl-2">
                                        <h5 className="text-[#1C2347] font-[500] text-[16px] leading-[18px]">
                                            Svayam Bhagavan Keshava Maharaj
                                        </h5>
                                        <div className="flex items-center pt-1 relative">
                                            <p className="text-[#C2C2BC] text-[12px] font-[400] pr-2">
                                                ID :{" "}
                                                <span id="textToCopy">
                                                    Q37eF2FN9QQLbNUZiNU7
                                                </span>
                                            </p>
                                            <i
                                                id="copyIcon"
                                                className="fa fa-files-o text-[14px] cursor-pointer text-[#489BF6] font-[800]"
                                                onClick={
                                                    props.copyTextToClipboard
                                                }
                                            ></i>
                                            <div>
                                                <span
                                                    id="copyMessage"
                                                    class="text-[#489BF6] font-bold absolute p-1 -mt-3.5 pl-3 rounded-md text-[14px] hidden"
                                                >
                                                    Copied!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-[12px] font-[400] py-2 text-[#646464]">
                                    Hi I am the AI voice of Svayam Bhagavan
                                    Keshava Maharaj. In the journey to
                                    comprehend the concept of God, an intriguing
                                    approach is to explore it through
                                    <span className="text-[#489BF6] text-[14px] font-[400] cursor-pointer pl-28">
                                        .....more
                                    </span>
                                </p>
                                <div className="flex justify-between my-2">
                                    <button className="rounded-l-[31px] rounded-r-[31px] bg-[#489BF626] flex items-center text-[14px] font-[400] text-[#489BF6] w-[80px] px-1 hover:scale-[1.05] duration-300">
                                        <img
                                            src="../images/addvoice/use.webp"
                                            className="pr-2"
                                            alt=""
                                        />
                                        USE
                                    </button>
                                    <div className="p-2 flex">
                                        <img
                                            src="../images/addvoice/arrow-down.webp"
                                            className="pr-2 cursor-pointer"
                                            alt=""
                                        />
                                        <img
                                            src="../images/addvoice/trash.webp"
                                            className="cursor-pointer"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddVoiceView;
