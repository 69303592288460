import { Message } from "../";

function SignInView(props) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    messageController={props.messageController}
                />
            )}
            <h5 className="text-left font-[600] text-[28px] leading-[34px] text-[#1C2347]">
                Sign In
            </h5>
            <p className="font-[300] text-[14px] text-[#444444] mb-5 mt-1">
                Sign in to your account to get dive into the metaverse
            </p>
            <div className="">
                <div>
                    {props.authType === "Phone and Otp" && (
                        <>
                            {!props.enterPhoneOtp && (
                                <div className="grid">
                                    {" "}
                                    <div>
                                        <div className="relative flex items-center border-[1px] border-[#C1C3C0] text-sm font-normal  rounded-lg">
                                            <div
                                                onClick={props.toggleDropdown}
                                                className="flex items-center p-4 cursor-pointer w-[90px] bg-[#489BF638] hover:bg-[#489BF6] group hover:rounded-lg"
                                            >
                                                <p className="font-[600] text-gray-700 group-hover:text-white">
                                                    {props.country}
                                                </p>
                                                <p className="font-[600] text-gray-700 group-hover:text-white pl-1">
                                                    {props.phoneCode}
                                                </p>

                                                <i className="fa fa-angle-down pl-1 group-hover:text-white"></i>
                                            </div>
                                            {props.isOpen && (
                                                <div className="absolute z-[9999] mt-56 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-[150px] w-[200px] overflow-y-auto">
                                                    <input
                                                        id="search-input"
                                                        className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                                                        type="text"
                                                        placeholder="Search items"
                                                        onChange={
                                                            props.handleSearchInput
                                                        }
                                                    ></input>
                                                    {props.CountryCodes.filter(
                                                        (item) =>
                                                            item.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    props.searchterm
                                                                        ? props.searchterm.toLowerCase()
                                                                        : ""
                                                                )
                                                    ).map((item, i) => (
                                                        <li
                                                            key={i}
                                                            className="block px-6 py-2 text-[14px] text-gray-700 text-center hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md"
                                                            value={
                                                                item.dial_code
                                                            }
                                                            onClick={(e) => {
                                                                props.togglePhone(
                                                                    "+" +
                                                                        e.currentTarget.value.toString()
                                                                );
                                                                props.toggleCountry(
                                                                    item.code
                                                                );
                                                                props.toggleDropdown();
                                                            }}
                                                        >
                                                            {item.name}(
                                                            {item.dial_code})
                                                        </li>
                                                    ))}
                                                </div>
                                            )}
                                            <input
                                                className="w-full text-grey px-4 max-md:px-2 bg-transparent input-number py-4 focus:outline-[#489BF6 text-[16px]]"
                                                type="number"
                                                placeholder="Enter phone no."
                                                name="phoneNumber"
                                                onChange={
                                                    props.handleInputChange
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <span className="italic text-[14px] font-[400] text-[#FF6767] mt-1">
                                        Note - Phone number with OTP is valid
                                        for India region only
                                    </span>
                                    <button
                                        onClick={() =>
                                            props.toggleEnterPhoneOtp(true)
                                        }
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        GET OTP
                                    </button>
                                </div>
                            )}
                            {props.enterPhoneOtp && (
                                <div className="grid">
                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                        OTP
                                    </label>
                                    <input
                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] input-number border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                        type="number"
                                        placeholder="Enter OTP"
                                        name="phone-otp"
                                        onChange={props.handleInputChange}
                                        required
                                    />
                                    <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                        <p>
                                            Didn’t get OTP ?{" "}
                                            <span className="font-[600] text-[#489BF6] cursor-pointer">
                                                Resend OTP
                                            </span>
                                        </p>
                                    </div>
                                    <button
                                        onClick={(e) => props.login("phone")}
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        Verify & Login
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {props.authType === "Email and Otp" && (
                        <>
                            {!props.enterEmailOtp && (
                                <div className="grid">
                                    {" "}
                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                        Email
                                    </label>
                                    <input
                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                        type="email"
                                        placeholder="Enter Email."
                                        name="email"
                                        onChange={props.handleInputChange}
                                        required
                                    />
                                    <button
                                        onClick={() =>
                                            props.toggleEnterEmailOtp(true)
                                        }
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        GET OTP
                                    </button>
                                </div>
                            )}
                            {props.enterEmailOtp && (
                                <div className="grid">
                                    <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                        OTP
                                    </label>
                                    <input
                                        className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1 input-number"
                                        type="number"
                                        placeholder="Enter OTP"
                                        name="email-otp"
                                        onChange={props.handleInputChange}
                                        required
                                    />
                                    <div className="text-[12px] font-[400] text-[#595959] leading-[16.41px] py-3">
                                        <p>
                                            Didn’t get OTP ?{" "}
                                            <span className="font-[600] text-[#489BF6] cursor-pointer">
                                                Resend OTP
                                            </span>
                                        </p>
                                    </div>
                                    <button
                                        onClick={(e) =>
                                            props.login("email-otp")
                                        }
                                        className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                    >
                                        Verify & Login
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {props.authType === "Email and Password" && (
                        <>
                            <div className="grid">
                                <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                    Email
                                </label>
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                    type="email"
                                    placeholder="Enter Email Address"
                                    name="email"
                                    onChange={props.handleInputChange}
                                    required
                                />
                                <label className="text-[#444444] text-[12px] font-[400] -mb-28 pl-4 pt-3">
                                    Password
                                </label>
                                <input
                                    className="w-full text-grey focus:text-[#1C2347] pt-6 pb-2 px-4 text-[16px]  max-md:px-2 bg-transparent border-[1px] border-[#C1C3C0] focus:outline-[#489BF6] rounded-md my-1"
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="Enter password"
                                    name="password"
                                    onChange={props.handleInputChange}
                                    required
                                />
                                <button
                                    onClick={(e) => props.login("email")}
                                    className="py-3 w-full my-3 font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] rounded-lg mr-4 hover:bg-gradient-to-l bg-gradient-to-r from-blue-500 to-purple-600"
                                >
                                    Login
                                </button>
                            </div>
                        </>
                    )}

                    <div
                        id="recaptcha-container"
                        className="px-10 max-md:px-4 flex justify-center"
                    ></div>
                </div>
                <div className="py-5 text-[#6B6B6B8F] font-[700]">
                    <hr />
                    <p className="text-center -mt-[14px]">
                        <span className="bg-[#FFFFFF] px-2">Or</span>
                    </p>
                </div>
                <button
                    onClick={(e) => props.login("google")}
                    className="login-page-buttons"
                >
                    <img
                        src="../images/login/googlelogo.webp"
                        alt=""
                        className="pr-2"
                    />
                    Sign in with Google
                </button>
                {props.authType !== "Email and Password" && (
                    <button
                        className="login-page-buttons"
                        onClick={() =>
                            props.toggleAuthType("Email and Password")
                        }
                    >
                        <img
                            src="../images/login/envelopeviolet.webp"
                            alt=""
                            className="pr-2"
                        />
                        Sign in Email and Password
                    </button>
                )}
                {props.authType !== "Email and Otp" && (
                    <button
                        className="login-page-buttons"
                        onClick={() => props.toggleAuthType("Email and Otp")}
                    >
                        <img
                            src="../images/login/envelopeorange.webp"
                            alt=""
                            className="pr-2"
                        />
                        Sign in with Email & OTP
                    </button>
                )}
                {props.authType !== "Phone and Otp" && (
                    <button
                        className="login-page-buttons"
                        onClick={() => props.toggleAuthType("Phone and Otp")}
                    >
                        <img
                            src="../images/login/phone.webp"
                            alt=""
                            className="pr-2"
                        />
                        Sign in with Phone Number
                    </button>
                )}
            </div>
        </>
    );
}

export default SignInView;
