export default function MessageView(props) {
    return (
        <>
            {props.messageText && (
                <div className="message-container">
                    {
                        <div
                            className={`${props.messageClass === "error" ? "error-message-box" : "success-message-box"}`}
                        >
                            <div>
                                <i
                                    className={`${props.messageClass === "error" ? "fa fa-times-circle text-red text-[32px]" : "fa fa-check-circle text-green text-[32px]"}`}
                                ></i>
                                <div>
                                    <h4>
                                        {props.messageClass === "error"
                                            ? "Error"
                                            : "Success"}
                                    </h4>
                                    <h5>{props.messageText}</h5>
                                </div>
                                <p onClick={() => props.messageController()}>
                                    Close
                                </p>
                            </div>
                        </div>
                    }
                </div>
            )}
        </>
    );
}
