import "firebase/auth";
import {
    auth,
    googleProvider,
    signinWithPopup,
    signinWithPhoneNumber,
    RecaptchaVerifier,
} from "./Firebase.service";

const FirebaseAuthService = {
    signinWithGoogle: async () => {
        try {
            return await signinWithPopup(auth, googleProvider);
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    signinWithPhoneNumber: async (phoneNumber) => {
        try {
            const appVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {}
            );
            return await signinWithPhoneNumber(auth, phoneNumber, appVerifier);
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    confirmOTPToSigninWithPhoneNumber: async (confirmationResult, otp) => {
        try {
            return await confirmationResult.confirm(otp);
        } catch (err) {
            console.log(err);
            return false;
        }
    },
};

export default FirebaseAuthService;
